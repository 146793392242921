@import '../../../../styles/abstracts/variables.scss';

.works-tab {
    min-height: 380px;
    &-tri-buttons-container {
        overflow: hidden;
        margin: 0px 10% 7%;

        .works-tab-tri-button {
            display: block;
            width: 80%;
            margin: 20px auto;
        }

    }

    .pagination-bar {
        margin-top: 1.7rem;
    }
}

@media only screen and (min-width: $medium) {
    .works-tab {
        min-height: 280px;
        .works-tab-tri-button {
            float: left;
            width: 30%;
            margin: 0px 1.6%;

        }
    }
}