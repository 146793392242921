@import '../../../styles/abstracts/variables';

.pagination-container {
  display: flex;
  list-style-type: none;
  align-items: center;
  justify-content: center;

  .pagination-item {
    padding: 0 4px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;

    .pagination-btn-container {
      height: 1.5rem;
      letter-spacing: 0.01071rem;
      border-radius: 16px;
      line-height: 1.43;
      font-size: 13px;
      min-width: 1.5rem;
      border: none;
      background-color: transparent;

      &.dots:hover {
        background-color: transparent;
        cursor: default;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
      }

      &.selected {
        background-color: rgba(0, 0, 0, 0.08);
      }

      .arrow {
        &::before {
          position: relative;
          /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
          content: '';
          /* By using an em scale, the arrows will size with the font */
          display: inline-block;
          width: 0.4rem;
          height: 0.4rem;
          border-right: 0.12rem solid rgba(0, 0, 0, 0.87);
          border-top: 0.12rem solid rgba(0, 0, 0, 0.87);
        }

        &.left {
          transform: rotate(-135deg);
        }

        &.right {
          transform: rotate(45deg);
        }
      }

      &.disabled {
        pointer-events: none;

        .arrow::before {
          border-right: 0.12rem solid rgba(0, 0, 0, 0.43);
          border-top: 0.12rem solid rgba(0, 0, 0, 0.43);
        }

        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }



    }
  }
}

@media only screen and (min-width: $small) {

  .pagination-container {
  
    .pagination-item {
      padding: 0 12px;
    }
  }
}