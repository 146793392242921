@import '../../../../styles/abstracts/variables';

.user-accept-reject-prompt {
    display: flex;
    flex-direction: column;
    height: 100%;
    
    .prompt-header {
        padding-top: 20px;
    }
    
    .prompt-btn {
        flex: 1;
        // max-width: 200px;
        margin: 10px;

        &-container {
        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: center;
        }
    }
    
    .prompt-header, .prompt-btn-container {
        height: 50%;
        min-height: 100px;
    }
}

@media only screen and (min-width: $large) {
    .user-accept-reject-prompt {

        .prompt-btn-container {
            flex-direction: row;
            align-items: center;
        }
    }
}