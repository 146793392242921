.delete-work-modal {
    &-header {
        margin-bottom: 15px;
    }
    .modal-box {
        height: auto;
        .action-btn {
            &-container {
            margin-top: 15px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            }
            min-width: 200px;
            margin-bottom: 10px;
        }
    }
}