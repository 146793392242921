@import '../../../../styles/abstracts/variables';

.upload-new-issue-tutorial {
    .example-link {
        word-break: break-word;
        font-style: italic;
        font-weight: $normal;
    }

    .note {
        font-style: italic;
        font-weight: $normal;
    }
}