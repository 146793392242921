.upload-perc-modal {
    .modal-box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .upload-perc-para {
        margin-bottom: 10px;
    }
}