@import '../../../styles/abstracts/variables';

.loading-spinner {
  .loading {
    &-content {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      margin: auto;
    }
    
    &-circle {
      top: 0;
      width: 200px;
      height: 100px;
      position: absolute;
      border: 2px solid $violet-blue;
      border-bottom: 0;
      transform-origin: 50% 100%;
      background-color: transparent;
      border-top-left-radius: 110px;
      border-top-right-radius: 110px;
      -ms-transform-origin: 50% 100%;
      -webkit-transform-origin: 50% 100%;
      animation: loading 1.5s linear infinite;
      -webkit-animation: loading 1.5s linear infinite;
    }

    &-name {
      color: $violet-blue;
      cursor: default;
      position: relative;
      font-family: Tahoma, Arial;
    }
  }

  @-webkit-keyframes loading {
    from {
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    to {
      -ms-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes loading {
    from {
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    to {
      -ms-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  &.small .loading-content .loading-circle {
    width: 25px;
    height: 12px;
  }

  &.large .loading-content {
    width: 200px;
    height: 200px;
  }
}