@import '../../styles/abstracts/variables';

.not-found-page {
    .header {
        font-weight: $bold;
        margin: 2rem 0;
        font-size: 3rem;
    }

    .desc {
        font-weight: $semibold;
        font-size: 1.2rem;
        margin-bottom: 1rem;
        line-height: 2rem;
        letter-spacing: 1px;

        .back-to-home-link {
            color: $violet-blue;
        }
    }
}