.modal-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background-color: rgba(0,0,0,.3);
    }
    .modal-box {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 50%;
        width: 90%;
        overflow: scroll;
        background-color: $pure-white;
        z-index: 2;
        padding: 40px;
        max-width: 700px;
        max-height: 350px;

        .modal-head {
            margin-bottom: 20px;
        }

        .modal-spacing-md-top {
            margin-top: 15px;
        }

        .modal-close-btn {
            position: absolute;
            top: 0;
            left: 0;
            background: none;
            padding: 10px;
            border: none;
           
            &:hover {
                color: $error-red;
            }
        }
    }
}

@media only screen and (min-width: $small) {
    .modal-wrapper {
        .modal-box {
            width: 80%;
        }
    }
}

@media only screen and (min-width: $medium) {
    .modal-wrapper {
        .modal-box {
            width: 50%;
        }
    }
}