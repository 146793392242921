@import '../../styles/abstracts/variables.scss';

.global-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $light-grey;
    position: relative;

    .global-nav-item {
        height: 60px;
    }

    .logo {
        font-size: 20px;
        font-weight: $semibold;
        padding: 0 10px;
        // flex-basis: 200px;

        .logo-img {
            display: block;
            width: 100%;
        }
    }

    a {
        text-decoration: none;
        outline: transparent;
        color: $off-black;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        &:hover {
            color: $violet-blue;
        }
    }

    ul {
        padding: 0;
        margin: 0;

        li {
            list-style: none;

        }
    }

    /* Mobile Menu */
    .nav-menu {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        order: 4;
        width: 100%;
        margin-left: auto;
        padding: 0px 10px;
        height: unset;

        li {
            .link-button  {
                border: none;
                background: transparent;
                font-family: $Roboto, $base-font;
                font-size: inherit;
                width: 100%;
            }
        }
        
        .nav-menu-item {
            width: 100%;
            text-align: center;
            display: none;
            height: 100%;
            padding: 20px;

            .link-button {
                .link-profile-img {
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                }
            }
            
        }

        &.active .nav-menu-item {
            display: block;
        }

    }

    .logo {
        order: 2;
        flex-basis: 200px;
    }

    .mobile-toggle {
        order: 1;
        // padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 60px;
        border: none;
        background-color: transparent;

        &:hover {
         background-color: $mid-gray;
        }
    }

    .search {
        order: 3;
        // margin-left: auto;
        // padding: 0 10px;
        flex-basis: 60px;
        border: none;
        background: transparent;

        &.active {
            background-color: $error-red;
            color: $pure-white;
        }
    }

    /* submenu styles */
    .submenu {
        display: none;

        .subitem {
            margin: 10px 0;
        }

        &.active {
            display: block;
        }
    }
}

.searchbar-container {
    position: absolute;
    width: calc(100% - 60px);
    top: 0;

    .search-form {
        height: 100%;

        input[type="text"].nav-searchbar {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            padding-left: 25px;
            outline: transparent;
            height: 100%;
            width: 100%;
            font-size: 1em;
            border: none;
            background-color: $white-light-grey;

            &::placeholder {
                font-weight: $light;
                font-family: $Roboto, $base-font;
            }

        }

    }
}

@media only screen and (min-width: $large) {
    .global-nav {
        .logo a {
            width: auto;
        }

        .nav-menu {
            order: 2;
            padding: 0px;
            width: auto;
            height: 60px;

            li {

                a, .link-button {
                    color: $gray-black;
                    font-weight: $light;
                }
            }

            .nav-menu-item {
                width: auto;
                display: block;
                padding: 0 20px;
            }


            li.has-submenu {
                display: flex;
                position: relative;

                .submenu {
                    position: absolute;
                    background: $pure-white;
                    width: 10em;
                    right: 5px;
                    top: 60px;
                    border: 1px solid $light-grey;
                    z-index: 1;
                }
            }

            &.active .nav-menu-item {
                display: flex;
            }
        }

        .logo {
            order: 1;
            padding: 0 20px;
            flex-basis: 225px;
        }

        .mobile-toggle {
            order: 3;
            display: none;
        }

        .search {
            order: 4;
            margin-left: unset;
            padding: 0 20px;
        }
    }

    .searchbar-container {

        .search-form {
            input[type="text"].nav-searchbar {
                font-size: 1.2em;
            }
        }
    }
}