@import '../../styles/abstracts/variables.scss';

.work-credits {
    position: relative;

    .work-credits-search-container {

        .search-bar {
            border: 1px solid $light-grey;
            margin-bottom: 15px;
        }

        .search-icon {
            position: relative;
            bottom: 50px;
            left: calc(100% - 30px);
            color: $mid-dark-gray;
        }
    }

    .work-credit-search-list {
        background: $pure-white;
        list-style-type: none;
        border-radius: 10px;
        padding: 10px 0px;
        border: 1px solid $light-grey;
        cursor: pointer;
        margin-bottom: 15px;

        &-item {
            padding: 10px;

            &:hover {
                background: $white-light-grey;
            }

            &-btn {
                border: 0;
                background: transparent;
                width: 100%;
                text-align: left;
                font-size: 1rem;
            }
        }
    }

    .work-credit-selected-search-list-item {
        margin-bottom: 15px;
        border: 1px solid $light-grey;
        border-radius: 10px;
        background: $pure-white;

        .username {
            width: 100%;
            text-align: center;
            padding: 15px 0px;
            background-color: $white-light-grey;
            border-radius: 10px 10px 0 0;
            border-bottom: 1px solid $light-grey;
            margin-bottom: 15px;
        }

        .info-head {
            margin: 0 10px;
        }

        .user-input {
            display: none;
        }

        ul.work-credits-checkbox-section {
            list-style-type: none;

            // position: relative;
            li {
                padding: 15px 0;
                border-bottom: 1px solid #f0f2f3;
                margin: 0px 10px;

                label.checkbox-item {
                    // position: relative;
                    // left: 37%;

                    input[type="checkbox"]+*::before {
                        margin-right: 10px;
                    }
                }
            }
        }

        .delete-work-credits-button {
            width: 50px;
            height: 50px;
            background-color: transparent;
            display: block;
            margin: 10px auto;
            text-align: center;
            color: $error-red;
            border: none;
            border-radius: 10px;
        }
    }

    .wc-error {
        margin-top: 15px;
        margin-left: 10px;
    }
}