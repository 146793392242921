@import '../../../styles/abstracts/variables';

// basic accordian styling
.accordian {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .accordian-trigger {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        text-align: center;
        cursor: pointer;
        border: 1px solid $mid-gray;
        color: #3143A8;
        background-color: $white-light-grey;
        &:not(:last-child) {
            border-bottom: 0;
        }

        .accordian-header {
            padding: 2rem;
            font-size: 1.4rem;
            text-transform: capitalize;
        }

        .accordian-icon {
            margin-right: 1.5rem;
            color: #8CACEB;
        }
        &:not(.active):hover {
            background-color: #fcfcfc;
        }
    }

    .dropdown {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 0rem 2rem 2rem;
        font-weight: 300;
        font-size: 1rem;
        border: 1px solid #ddd;
        border-top: 0;

        &:not(:last-child) {
            border-bottom: 0;
        }

        ul.accordian-list {
            list-style-type: none;
            flex: 1;
            
            li {
                margin-bottom: .5rem;
            }
        }
    }
}


// accredited grid layout 
.accredited-works-accordian {

    .dropdown {
        justify-content: center;


        .accordian-list {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 2rem 0rem;
            list-style-type: none;
            margin: 0;
            padding: 0;

            & > li.accordian-list-item {
                word-break: break-word;
                
                .accredited-work-group {
                    list-style-type: none;

                    &-item-link {
                        color: $dark-violet-blue;
                        text-decoration: none;

                        &:hover {
                            color: $violet-blue;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: $small) {
    .accredited-works-accordian {

        .dropdown {
            .accordian-list {
                grid-template-columns: repeat(2, 1fr);

                & > li.accordian-list-item {
                    padding-left: 2rem;
                }
            }
        }
    }
}

@media only screen and (min-width: $medium) {
    .accredited-works-accordian {

        .dropdown {
            .accordian-list {
                grid-template-columns: repeat(3, 1fr);
                gap: 1.5rem 0rem;
            }
        }
    }
}