@import '../../../styles/abstracts/variables';


.article-page {
    display: flex;
    flex-wrap: wrap;

    .article {

        &-main,
        &-aside {
            flex: 0 0 100%;
            max-width: 100%;
        }

        &-main {
            padding: 0 1rem;

            &.only {
                padding: 0 1.5rem;
                margin-bottom: 3rem;
            }
        }

        &-aside {
            padding: 0 1rem;
        }

        &-header {
            margin: 2rem 0 1rem;

            &-title {
                font-size: 1.5rem;
            }

        }

        &-sub-header {
            margin-bottom: 0.8rem;

            .article-header-sub-title {
                font-weight: $light;
                color: $gray-black;
                font-size: 1.1rem;
                line-height: 1.5;

                &.black {
                    color: $pure-black;
                }
            }
        }

        &-creation-details {
            font-size: .9rem;
            display: flex;
            justify-content: space-between;
            color: $gray-black;
            font-weight: $light;

            .extra-details {
                .extra-details-item {
                    &:not(:last-child):after {
                        content: "\2022";
                        margin: 0.2rem;
                        color: $gray-black;
                    }
                }
            }
        }

        &-hr {
            height: 1px;
            background-color: $light-grey;
            border: none;

            &-spacing {
                margin: 2rem 0;
            }
        }

        &-para {
            font-weight: $light;
            line-height: 1.6;
            font-size: 1.1rem;
            margin-bottom: 2rem;
        }

        &-figure {
            width: 100%;
            margin-bottom: 2rem;

            &-img {
                width: 100%;
            }

            &-cap {
                font-size: .9rem;
                text-align: center;
                color: $dark-charcoal;
            }
        }

        &-video {
            width: 100%;
        }

        &-list {
            padding-left: 1rem;

            &.position-inside {
                list-style-position: inside;
            }

            li {
                margin-bottom: 20px;
                padding-left: 10px;

                &.normal-bold-marker {
                    &::marker {
                        font-weight: $normal;
                    }
                }

                &.semi-bold-marker {
                    &::marker {
                        font-weight: $semibold;
                    }
                }

                &.bold-marker {
                    &::marker {
                        font-weight: $bold;
                    }
                }

                .inline-li {
                    display: inline;
                }
            }
        }

    }

    // bold
    .normal-bold {
        font-weight: $normal;
    }

    .semi-bold {
        font-weight: $semibold;
    }

    .bold {
        font-weight: $bold;
    }

    /* spacing start */
    
    // margin top 
    .mt {
        // small 
        &-sm {
            margin-top: 10px;
        }
        // medium
        &-md {
            margin-top: 30px;
        }
        // large
        &-lg {
            margin-top: 50px;
        }
    }

    .my {
        // small 
        &-sm {
            margin-top: 10px;
            margin-bottom: 10px;
        }
        // medium
        &-md {
            margin-top: 30px;
            margin-bottom: 30px;
        }
        // large
        &-lg {
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }


    .mb {
        // small 
        &-sm {
            margin-bottom: 10px;
        }
        // medium
        &-md {
            margin-bottom: 30px;
        }
        // large
        &-lg {
            margin-bottom: 50px;
        }
    }

    /* spacing end */
    


}

@media only screen and (min-width: $small) {
    
}
@media only screen and (min-width: $medium) {
    .article-page {
        flex-wrap: nowrap;
        justify-content: center;

        .article {
            &-main {
                flex: 1 0 60%;
                max-width: 1000px;

                &.only {
                    max-width: 700px;
                    flex-grow: 0;
                    padding: unset;
                    margin-bottom: 5rem;
                }
            }

            &-aside {
                flex: 0 0 25%;
                max-width: 400px;
                padding: 0 1rem 0 0;
            }

            &-header {
                margin: 3rem 0 2rem;

                &-title {
                    font-size: 2.3rem;
                }
            }

            &-sub-header {
                margin-bottom: 0.8rem;
    
                .article-header-sub-title {
                    font-size: 1.3rem;
                }
            }

            &-figure {
                &-img {
                    &.md-img {
                        width: 80%;
                        margin: 0 auto;
                        display: block;
                    }
                }
            }

            &-list {
                padding-left: 2rem;
            }
        }
    }
}