.bsc-button {
    border: unset;
    font-weight: $normal;
    font-size: $small-font;
    padding: 15px 30px;
    font-family: $Roboto, $base-font;

    &.primary {
        background: $violet-blue;
        color: $pure-white;
        border-radius: 5px;

        &:hover,
        &.active {
            background: $dark-violet-blue;
        }

        &.primary-round {
            border-radius: 35px;
        }

        &.primary-glow {
            box-shadow: 0 2px 15px $violet-blue;

            &:hover,
            &.active {
                box-shadow: 0 2px 15px $dark-violet-blue;
            }
        }
    }

    &.transparent {
        background-color: transparent;
        color: $pure-black;
        border-radius: 35px;
        border: 1px solid $pure-black;

        &:hover,
        &.active {
            color: $violet-blue;
            border-color: $violet-blue;
        }

        &.transparent-blue {
            color: $dark-violet-blue;
            border-color: $dark-violet-blue;
    
            &:hover,
            &.active {
                color: $violet-blue;
                border-color: $violet-blue;
            }
        }

        &.transparent-black {
            color: $gray-black;
            border-color: $gray-black;
    
            &:hover,
            &.active {
                color: $white-light-grey;
                background-color: $gray-black;
            }
        }
    }

    &.primary-link {
        border: unset;
        color: $violet-blue;
        background: transparent;
        padding: unset;
        line-height: 1.5;
        letter-spacing: 1px;
        font-size: 15px;

        &:hover,
        &.active {
            color: $dark-violet-blue;
        }
    }

    &.transparent-red {
        color: $error-red;
        border-color: $error-red;

        &:hover,
        &.active {
            color: $dark-red;
            border-color: $dark-red;
        }
    }

    &.close-btn {
        color: $error-red;
        padding: 0;
        background-color: transparent;

        &:hover,
        &.active {
            color: $dark-red;
        }
    }

    &.not-round {
        border-radius: 0;
    }
}