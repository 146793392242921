@import '../../../styles/abstracts/variables';

.article-items {
    display: flex;
    flex-direction: column;
    min-height: 150px;
    margin-bottom: 2rem;

    .article-img-wrapper {
        display: flex;
        flex: 0 0 100%;

        .article-img {
            display: block;
            max-width: 100%;
            align-self: center;
            // height: auto;
            min-height: 100%;
            object-fit: cover;
        }
    }

    .article-body {
        flex: 1;
        display: flex;

        .content-wrapper {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;

            .link {
                color: unset;
                text-decoration: unset;

                .header,
                .desc {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .header {
                    font-size: 1.1rem;
                    font-weight: $normal;
                    // font-weight: $semibold;
                    margin: 1rem 0 0.5rem;
                }

                .desc {
                    font-size: .9rem;
                    line-height: 1.3;
                    color: $kinda-gray-kinda-black;
                    margin-bottom: 0.8rem;
                }
            }

            .extra-details,
            .header {
                letter-spacing: 0.04rem;
            }

            .extra-details,
            .desc {
                font-weight: $light;
                // color: $gray-black;

            }

            .extra-details {
                // margin-top: 0.5rem;
                font-size: .8rem;
                display: flex;
                justify-content: space-between;
                color: $gray-black;

                .left-details {
                    .left-details-item {
                        &:not(:last-child):after {
                            content: "\2022";
                            margin: 0.2rem;
                            color: $gray-black;
                        }
                    }
                }

                .right-details .article-type {
                    background-color: $mid-gray;
                    border-radius: 15px;
                    padding: 5px;
                    color: $gray-black;
                }
            }

        }
    }

}


@media only screen and (min-width: $small) {
    .article-items {
        flex-direction: row;

        .article-img-wrapper {
            // flex: 0 0 250px;
            flex: 0 0 30%;
            order: 2;
        }

        .article-body {
            order: 1;

            .content-wrapper {
                padding-right: 1.5rem;

                .link {
                    .header {
                        margin-top: 0;
                    }

                    .desc {
                        margin-bottom: 0.5rem;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: $medium) {
    .article-items {

        .article-img-wrapper .article-img {
            min-height: unset;
            object-fit: unset;
        }


        .article-body .content-wrapper .link {
            .header {
                font-size: 1.3rem;
            }

            .desc {
                font-size: 1rem;
            }
        }

    }
}