
.progress-bar {
    width: 100%;
    height: 30px;
    border: 1px solid $pure-black;
    border-radius: 7px;
    padding: 4px;
    position: relative;

    .progress-label {
        position: absolute;
        top: 5px;
        left: 48%;    
    }
}

.progress {
    width: 0%;
    background-color: $violet-blue;
    height: 100%;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    border-color: $violet-blue;
    -webkit-transition: width 2s;
    /* For Safari 3.1 to 6.0 */
    transition: width 2s;
}