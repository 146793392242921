.display-work-grid {
    display: grid;
    grid-template-columns: 1fr;
    list-style: none;
    justify-content: space-evenly;
    max-width: 90%;
    margin: 0 auto;
    grid-gap: 50px;
    place-items: start center;


    // mimics bootstrap column sizes

    &.col-sm-3 {
        grid-template-columns: 1fr 1fr 1fr;
    }

    &.col-sm-4 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    li.grid-list-item {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        height: max-content;
        align-content: center;
        max-width: 300px;

        .grid-image-container {
            img.grid-image {
                display: block;
                width: 100%;
            }
        }

        .grid-info-box {
            padding-top: 1rem;

            &-header {
                line-height: 1.5;
                letter-spacing: 1px;
            }

            &-header-container {
                min-height: 45px;
                align-items: center;
                font-size: 1rem;
            }

            &-header {
                margin-bottom: 5px;
                font-weight: $normal;
                word-break: break-word;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                font-size: .9em;

                &-link {
                    color: $pure-black;
                    text-decoration: none;
                    
                    &:hover {
                        text-decoration: underline;
                    }
                }

                &:nth-child(2n) {
                    font-weight: $light;
                }
            }

            &-date-created {
                font-weight: $light;
                color: $dark-violet-blue;
                font-size: .9em;
            }
        }
    }
}

@media only screen and (min-width: $small) {

    .display-work-grid {

        &.col-sm-2 {
            grid-template-columns: 1fr 1fr;
        }
    }
}

@media only screen and (min-width: $medium) {

    .display-work-grid {
        grid-gap: 35px;

        &.col-2 {
            grid-template-columns: 1fr 1fr;
        }

        &.col-3 {
            grid-template-columns: 1fr 1fr;
        }

        &.col-4 {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

@media only screen and (min-width: $large) {

    .display-work-grid {

        &.col-2 {
            grid-template-columns: 1fr 1fr;
        }

        &.col-3 {
            grid-template-columns: 1fr 1fr 1fr;
        }

        &.col-4 {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        &.col-5 {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }
    }
}