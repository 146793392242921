// @import '../../index.scss';

.auth-pages {
    margin-bottom: 3rem;
    .auth-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
    
        .auth-form {
            margin: 0 auto;

            input.auth-input,
            button.auth-submit {
                width: 100%;
            }
        }
    }
    .status-message {
        text-align: center;
    }
}