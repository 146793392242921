.edit-profile-page {
    margin-bottom: 3rem;
    
    .header-text {
        margin: 30px 0;
        font-size: 16px;
        line-height: 1.5em;
    }

    .warning {
        line-height: 1.5rem;
        margin-bottom: 1.6rem;
    }

    .deactivation-btn {
        display: block;
        margin: 0 auto;
    }

}

// .deactivate-account-modal {
//     color: palegoldenrod;
// }