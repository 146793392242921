form.bsc-form {
    width: 100%;

    input {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        padding-left: 10px;
        font-size: .9em;

        &::placeholder {
            font-weight: $light;
            font-family: $Roboto, $base-font;
        }
    }

    /* textarea */
    textarea {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 20px 10px;

        &::placeholder,
        &::-webkit-input-placeholder {
            font-weight: $light;
            font-family: $Roboto, $base-font;
            font-size: 15px;
        }

        &.form-textarea {
            width: 100%;
            height: 150px;
            margin-bottom: 30px;
        }
    }

    /* textarea end */

    /* single file input */
    .file-input-single-upload-container {
        position: relative;
        display: flex;
        margin-bottom: 30px;
        flex-wrap: wrap;

        .file-input-single-upload-field {
            opacity: 0;
            height: 50px;
            padding: 0;
            flex: 0 0 100%;
            display: none;
            // margin-bottom: 15px;
        }

        label.file-input-single-upload-trigger {
            background: $violet-blue;
            color: $pure-white;
            font-weight: $light;
            margin-bottom: 15px;
            text-align: center;
            position: relative;
            left: 0;
            top: 0;
            padding: 15.5px 5%;
            display: inline-block;
            flex-basis: 100%;

            &:hover {
                background: $dark-violet-blue;
            }
        }

        .file-input-single-upload-name {
            align-self: center;
            flex: 0 0 100%;
            text-align: center;
        }
    }

    /* single file input end */

    /* multi file input */
    .file-input-multiple-upload-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-width: 2px;
        border-radius: 2px;
        border-color: $violet-blue;
        border-style: dashed;
        background-color: #fafafa;
        outline: none;
        transition: border .24s ease-in-out;
        text-align: center;
        height: 250px;
        
        p {
            margin: auto;
        }
    }

    .file-input-multiple-upload-preview-container {

        h2.preview-issue-assets-header {
            font-size: 1rem;
        }

        ul.thumb-nails {
            list-style-type: none;

            li {
                // display: inline-flex;
                padding: 10px;
            }

            button.thumb-nail-remove-icon {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                color: white;
                background-color: $violet-blue;
                border: none;

                &:hover {
                    background-color: $dark-violet-blue;
                }
            }

            .thumb-nail-caption {
                text-align: center;
                font-weight: $light;
            }

            img.thumb-nail-img {
                width: 250px;
                margin: 0 auto;
                display: block;
            }
        }
    }

    /* multi file input end */

    /* checkbox input */

    label.checkbox-item {
        white-space: nowrap;

        input[type="checkbox"] {
            display: none;

            &+*::before {
                content: "";
                display: inline-block;
                vertical-align: bottom;
                width: 1rem;
                height: 1rem;
                border-radius: 10%;
                border-style: solid;
                border-width: 1.5px;
                border-color: $light-grey;
                background-color: $pure-white;
                margin-right: 5px;
            }

            &:checked+* {
                color: $violet-blue;
            }

            &:checked+*::before {
                content: "\2713\0020";
                color: $violet-blue;
                text-align: center;
            }

            &:disabled:checked+*::before {
                color: $mid-dark-gray;
            }

            &:disabled+*::before {
                background-color: $mid-gray;
                color: $mid-dark-gray;
            }
        }

    }

    /* checkbox input end */

    /* form submit */
    .form-submit {
        background-color: $violet-blue;
        color: $pure-white;
        border: none;

        &:hover { 
            background-color: $dark-violet-blue;
        }

        &[disabled=disabled], &:disabled {
            background-color: $disabled-violet-blue; 
        }
    }

    /* form submit end */


    /* general form styles */
    .form-item {
        height: 50px;
        margin-bottom: 20px;
    }

    .form-input {
        border: 1px solid $light-grey;
        display: block;
    }

    .form-header-text {
        margin: 30px 0;
        font-size: $medium-font;
        line-height: 1.5em;
    }

    .form-header-subtext {
        font-size: $small-font;
        margin-bottom: 30px;
        line-height: 1.5em;
    }

    .error-message {
        margin-bottom: 30px;
    }

    /* general form styles end */

}

@media only screen and (min-width: $medium) {
    form.bsc-form {

        /* textarea */
        textarea {

            &.form-textarea {
                margin-bottom: 15px;
            }
        }
        /* textarea end */

        /* single file input */
        .file-input-single-upload-container {
            margin-bottom: 15px;

            .file-input-single-upload-field {
                margin-bottom: 0;
                // flex and min-width need to remain the same here
                flex: 0 0 30%;
                min-width: 30%;
            }

            label.file-input-single-upload-trigger {
                flex-basis: 30%;
                margin-bottom: 0;
            }

            .file-input-single-upload-name {
                // flex and min-width need to remain the same here
                flex: 0 0 55%;
                min-width: 55%;
                padding-left: 5%;
                text-align: left;
            }
        }

        /* single file input end */

        /* multi file input */
        .file-input-multiple-upload-container {
            margin-bottom: 15px;
        }

        /* multi file input end */

        /* general form styles */
        .form-item {
            margin-bottom: 15px;
        }

        .form-header-subtext {
            margin-bottom: 15px;
        }

        .error-message {
            margin-bottom: 15px;
        }

        /* general form styles end */


    }
}