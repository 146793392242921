@import '../../styles/abstracts/variables.scss';

.upload-page {
    margin-bottom: 3rem;
    .upload-form-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;

        .upload-form {
            margin: 0 auto;

            input[type=text],
            input[type=email],
            button.form-submit {
                width: 100%;
            }

            .file-input-single-upload-container {
                .file-input-single-upload-field {}

            }

            .file-input-multiple-upload-container {}

            .checkbox-group {
                list-style-type: none;
                margin-bottom: 15px;
                li {
                    margin-bottom: 5px;
                }
                &.upload-checkboxes {
                    columns: 1;
                    max-width: 200px;
                    margin: 0 auto 15px;
                }
            }

            .radio-btn-group {
              .radio-label {
                .radio-btn {
                    margin-right: 5px;
                }
                margin-right: 10px;
              }
            }
        }

        .slug-field {
            margin-bottom: 5px;
        }

        .edit-upload-loading-spinner {
            margin: 5rem auto;
        }

        .delete-book-btn-section {
            .delete-book-btn {
              margin: 0 auto;
              display: block;

                &-header {
                  font-size: 16px;
                  line-height: 1.5em;
                }
            }
            margin-top: 40px;
        }
    }
}

@media only screen and (min-width: $medium) {
    .upload-page .upload-form-container .upload-form .checkbox-group.upload-checkboxes {
        column-count: 2;
        max-width: 400px;
    }
}