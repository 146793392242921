@import '../../styles/abstracts/variables';

.articles-page {

    .articles-container {
        width: 90%;
        margin: 3rem auto 0;
    }
}

@media only screen and (min-width: $medium) {
    .articles-page {
    
        .articles-container {
            max-width: 700px;
        }
    }
}