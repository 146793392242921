@import '../../styles/abstracts/variables.scss';

.bsc-footer {
    min-height: 100px;
    background-color: $dark-charcoal;
    display: flex;
    color: $white-light-grey;
    align-items: center;
    justify-content: center;

    .footer-text {
        flex: 0 0 150px;
        font-weight: $light;
    }
}