* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    font-family: $Roboto, $base-font;
    background-color: $bg-color;
    margin: 0;
}

html,
body {
    height: 100%;
}

.bsc {

    .success-text-color {
        color: $violet-blue;
    }

    .error-text-color {
        color: $error-red;
    }

    .text-blue {
        color: $violet-blue;
    }

    .text-center {
        text-align: center;
    }

    .min-vh100 {
        min-height: 100vh;
    }

    .container {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .container-fluid {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .hidden-header {
        font-size: 0px;
    }
}

@media only screen and (min-width: $medium) {
    .bsc {
        .container {
            width: 60%;
        }
    }
}