@import '../../styles/abstracts/variables.scss';

.home-page {
    // max-width: 1800px;
    // margin: 0 auto;
    .hero-container {
        display: flex;
        // position: relative;
        width: 100%;
        flex-wrap: wrap;

        .hero-image-container {
            // overflow: hidden;
            flex: 0 0 100%;
            // height: 50vh;
            // background-repeat: no-repeat;
            // background-size: cover;
            // background-position: center;
            // height: 350px;
            display: block;

            .hero-picture {         
                width: 100%;
                display: block;
                // height: 100%;
                // max-height: 350px;
            }

            .hero-image {         
                // width: 100%;
                // display: block;
                // max-height: 350px;
                display: block;
                width: 100vw;
                height: 100vh;
                max-height: 350px;
                object-fit: cover;
            }
        }

        .desc {
            display: flex;
            justify-content: center;
            // width: 55%;
            flex: 0 0 100%;
            // margin: 0 auto;
            min-height: 300px;
            align-items: center;
            // order: 2;

            .desc-content {
                padding: 20px 0;
                display: flex;
                flex-direction: column;
                flex-basis: 55%;
            

            .primary-header {
                // flex: 1;
                text-align: center;
                font-size: 2rem;
                margin-bottom: 0.8rem;
                color: $violet-blue;
            }

            .details {
                // flex: 1;
                font-size: 1.1rem;
                font-weight: $light;
                line-height: 1.5;
                text-align: center;
            }

            .link-button {
                display: block;
                margin: 12px auto 0 auto;
            }
        }
        }

        &.sect-one .hero-image-container {
            // background-image: url('../../assets/wolverine.jpeg');
        }
        
        &.sect-two .hero-image-container {
            // background-image: url('../../assets/supergirl.jpeg');
        }
       
        &.sect-three .hero-image-container {
            // background-image: url('../../assets/starlight.jpeg');
        }
    }
}

@media only screen and (min-width: $medium) {
    .home-page {
        .hero-container {
            align-items: stretch;
            flex-wrap: nowrap;
            .desc {
                // position: absolute;
                // flex: 0 0 45%;
                // top: 0;
                height: inherit;
                // left: 0;
                // justify-content: flex-start;
                // min-height: unset;
                // align-items: unset;

                .desc-content {
                    // flex-basis: 41%;
                    // background-color: $disabled-violet-blue;
                    justify-content: center;
                    // z-index: 2;
                }

                &[class*="slant"] {

                    .desc-content {
                        flex-basis: 90%;
                        z-index: 2;
                    }

                     &:after, &:before {
                        display: none;
                        content: "";
                        width: 25%;
                        height: 100%;
                        z-index: 1;
                        position: relative;
                    }
                }

                &.left-slant {
                    &:after {
                        display: block;
                        transform: skewX(-7deg);
                        // background-color: $disabled-violet-blue;
                        left: 5%;
                    }

                    .desc-content {
                        // padding-left: 5%;
                    }

                }

                &.right-slant {
                    // order: 2;
                    &:before {
                        display: block;
                        transform: skewX(7deg);
                        // background-color: $disabled-violet-blue;
                        right: 5%;
                    }
                    .desc-content {
                        // padding-right: 5%;
                    }

                }
            }

            .hero-image-container { 
                // background-position-y: top;
                // flex: 0 0 55%;
                .hero-picture {
                    height: 100%;
                
                    .hero-image {         
                        // width: 100%;
                        // display: block;
                        // max-height: 350px;
                        display: block;
                        width: 100%;
                        object-fit: cover;
                        height: inherit;
                        max-height: unset;
                    }
                }
            }

            &[class*="sect"] {
                .desc {
                    .desc-content {
                        background-color: $white-light-grey;
                        position: relative;
                    }

                    &:after, &:before {
                        background-color: $white-light-grey;
                    }
                }
            }

            &.odd {
                .hero-image-container {
                    order: 2;
                }
                .desc-content {
                    left: 10%;
                }
            }

            &.even {
                .desc.right-slant {
                    order: 2;

                    .desc-content {
                        right: 15%;
                    }
                }
            }
            
            // &.sect-one {
            //     .hero-image-container {
            //         // background-position-x: 20rem;
            //     }
            // }
            // &.sect-two {
            //     .hero-image-container {
            //         // background-position-x: -12rem;
            //     }
            // }
            // &.sect-three {
            //     .hero-image-container {
            //         // background-position-x: 19rem;
            //     }
            // }

            .desc {
                flex: 0 0 45%;
            }

            .hero-image-container {
                flex: 0 0 55%;
            }

            // .desc, &.even .hero-image-container {
            //     flex: 0 0 45%;
            // }

            // .hero-image-container, &.even .desc {
            //     flex: 0 0 55%;
            // }
    

        }
    }
}