// colors
// $teal-green: #21D0B2;
// $dark-teal-green: #12b499;
// $blue: #1DCDFE;
$violet-blue: #4F80E2;
$dark-violet-blue: #3E54D3;
$disabled-violet-blue: #4f80e2;
// $dark-blue-violet: #3446B0;
$dark-charcoal: #2E2E2E;
$off-black: #202121;
$pure-black: #000000;
$gray-black: #3c3b37;
// $spanish-gray: #949494;
$mid-gray: #ddd;
$white-gray: #ccc;
$mid-dark-gray: #999;
$kinda-gray-kinda-black: #222222;
$tender-black: #2F455C;
$white-light-grey: #f4f7f6;
$light-grey: #DBE0DF;
// $light-grey-rbg: rgb(219, 224, 223);
// $light-blue-grey: #e4ecee;
$sea-shell: #F0F1F3;
$pure-white: #ffffff;
$error-red: #cc0028;
$dark-red: #880808;
$mauvelous: #F7A6A6;
$tea-green: #CFFDBC;

$bg-color: $white-light-grey;

// break-points
$x-large: 1200px;
$large: 992px;
$medium: 768px;
$small: 480px;
$x-small: 320px;

// Typography
$Roboto: 'Roboto';
$base-font: sans-serif;

// font weight
$light: 300;
$normal: 400;
$semibold: 500;
$bold: 700;

// font size
$medium-font: 16px;
$small-font: 13px;

